import { Link } from "react-router-dom";

const Team = () => {
	return (
		<div className="team-member-wrapper section-space--pt_100 section-space--pb_40">
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="section-title section-space--mb_60">
							<h3 className="heading">Meet the <span className="text-color-primary">Founders </span></h3>
							<p className="text mt-30">Mitech specializes in technological and IT-related services such as product engineering, warranty management, building cloud, infrastructure, network, etc. </p>

							<div className="sider-title-button-box mt-30">
								<Link to="#" className="ht-btn ht-btn-md">Join us now</Link>
								<Link to="#" className="btn-text font-weight--bold small-mt__20">View all team <i className="ml-1 button-icon far fa-long-arrow-right"></i></Link>
							</div>

						</div>
					</div>
					<div className="col-lg-8 ht-team-member-style-one">
						<div className="row">
							
							<div className="col-lg-6 col-md-6 wow move-up">
								<div className="grid-item">
									<div className="ht-team-member">
										<div className="team-image">
											<img className="img-fluid" src="mzungu.png" alt="" />
											<div className="social-networks">
												<div className="inner">
													<Link target="_blank" to="#" className=" hint--bounce  hint--top hint--theme-two" aria-label="Facebook"><i className="fab fa-facebook"></i>
													</Link>
													<Link target="_blank" to="#" className=" hint--bounce hint--top hint--theme-two" aria-label="Twitter"><i className="fab fa-twitter"></i>
													</Link>
													<Link target="_blank" to="#" className=" hint--bounce hint--top hint--theme-two" aria-label="Instagram"><i className="fab fa-instagram"></i>
													</Link>
												</div>
											</div>
										</div>
										<div className="team-info ">
											<h5 className="name">MICHEL MARIJNISSEN </h5>
											<div className="position">
												Co-Founder, Strategic Advisor <br />
												Civil Engineer Architect, International Project Developer
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 wow move-up">
								<div className="grid-item">
									<div className="ht-team-member">
										<div className="team-image">
											<img className="img-fluid" src="/orphee.png" width="370px" height="250px" alt="" />
											<div className="social-networks">
												<div className="inner">
													<Link target="_blank" to="#" className=" hint--bounce  hint--top hint--theme-two" aria-label="Facebook"><i className="fab fa-facebook"></i>
													</Link>
													<Link target="_blank" to="#" className=" hint--bounce hint--top hint--theme-two" aria-label="Twitter"><i className="fab fa-twitter"></i>
													</Link>
													<Link target="_blank" to="#" className=" hint--bounce hint--top hint--theme-two" aria-label="Instagram"><i className="fab fa-instagram"></i>
													</Link>
												</div>
											</div>
										</div>
										<div className="team-info ">
											<h5 className="name">Orpheus Mezetou ADOM</h5>
											<div className="position">
												Co-Founder, CEO <br />
												Engineer, Siemens MBA, Master of Vlerick Business Management School.
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Team;
