import PropTypes from "prop-types";

interface BreadcrumbProps {
  title: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title }) => {
  return (
    <div className="about-banner-wrap banner-space about-us-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto">
            <div className="about-banner-content text-center">
              <h2 className="mb-15 text-white">{title}</h2>
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Contact us </li>
              </ul>
              {/* <h5 className="font-weight--normal text-white">
                Mitech specializes in technological and IT-related services such
                as product engineering, warranty management, building cloud,
                infrastructure, network, etc.{" "}
              </h5> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Breadcrumb;
