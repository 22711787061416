import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-area-wrapper reveal-footer bg-gray">
      <div className="footer-area section-space--ptb_80">
        <div className="container">
          <div className="row footer-widget-wrapper">
            <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
              <div className="footer-widget__logo mb-30">
                <img
                  src="/logo.png"
                  width="120"
                  height="48"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <ul className="footer-widget__list">
                <li>Mail Lamartine 6, MONS-EN-BARŒUL</li>
                <li>
                  <Link
                    to="mailto:contact@aeroland.com"
                    className="hover-style-link"
                  >
                    orphee.adom@maoint.eu
                  </Link>
                </li>
                <li>
                  <Link
                    to="tel:123344556"
                    className="hover-style-link text-black font-weight--bold"
                  >
                    +33 7 72 30 75 35
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://hazika.net"
                    className="hover-style-link text-color-primary"
                  >
                    www.hazika.net
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <h6 className="footer-widget__title mb-20">Our Services</h6>
              <ul className="footer-widget__list">
                <li>
                  <Link to="#" className="hover-style-link">
                    Funds
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover-style-link">
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <h6 className="footer-widget__title mb-20">Quick links</h6>
              <ul className="footer-widget__list">
                <li>
                  <Link to="/aboutus" className="hover-style-link">
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="hover-style-link">
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover-style-link">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover-style-link">
                    Where to Find Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <h6 className="footer-widget__title mb-20">Support</h6>
              <ul className="footer-widget__list">
                <li>
                  <Link to="#" className="hover-style-link">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover-style-link">
                    Cookies Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <div className="footer-widget__title section-space--mb_50"></div>
              <ul className="footer-widget__list">
                <li>
                  <Link to="#" className="image_btn">
                    <img
                      className="img-fluid"
                      src="assets/images/icons/aeroland-button-google-play.webp"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#" className="image_btn">
                    <img
                      className="img-fluid"
                      src="assets/images/icons/aeroland-button-app-store.webp"
                      alt=""
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright-area section-space--pb_30">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center text-md-start">
              <span className="copyright-text">
                &copy; 2022 Hazika.{" "}
                <Link to="https://hazika.net/">All Rights Reserved.</Link>
              </span>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <ul className="list ht-social-networks solid-rounded-icon">
                <li className="item">
                  <Link
                    to="https://twitter.com"
                    target="_blank"
                    aria-label="Twitter"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-twitter link-icon"></i>
                  </Link>
                </li>
                <li className="item">
                  <Link
                    to="https://facebook.com"
                    target="_blank"
                    aria-label="Facebook"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-facebook-f link-icon"></i>
                  </Link>
                </li>
                <li className="item">
                  <Link
                    to="https://instagram.com"
                    target="_blank"
                    aria-label="Instagram"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-instagram link-icon"></i>
                  </Link>
                </li>
                <li className="item">
                  <Link
                    to="https://linkedin.com"
                    target="_blank"
                    aria-label="Linkedin"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-linkedin link-icon"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
