export default function FunFacts() {
  return (
    <div className="fun-fact-wrapper bg-gray section-space--ptb_120">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="modern-number-01 me-5">
              <h2 className="heading">
                <span className="mark-text">38</span>Years’ Experience in Sales
              </h2>
              <h3 className="heading">
                Learn more about our{" "}
                <span className="text-color-primary">Success Stories</span>
              </h3>
            </div>
          </div>
          <div className="col-lg-7 col-md-8">
            <div className="row">
              <div className="col-md-6 col-sm-6 wow move-up">
                <div className="fun-fact--three text-center">
                  <div className="fun-fact__count counter">1790</div>
                  <h6 className="fun-fact__text">Account numbers</h6>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 wow move-up">
                <div className="fun-fact--three text-center">
                  <div className="fun-fact__count counter">32</div>
                  <h6 className="fun-fact__text">Finished projects</h6>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 wow move-up">
                <div className="fun-fact--three text-center">
                  <div className="fun-fact__count counter">73</div>
                  <h6 className="fun-fact__text">Happy clients</h6>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 wow move-up">
                <div className="fun-fact--three text-center">
                  <div className="fun-fact__count counter">318</div>
                  <h6 className="fun-fact__text">Blog posts</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}