import { Link } from "react-router-dom";

export default function Features() {
  return (
    <div className="feature-images-wrapper bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="feature-images__five resolutions-hero-bottom">
              <div className="row">
                <div className="col-lg-4 col-md-6 wow move-up">
                  <div className="ht-box-images style-05">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <div className="default-image">
                          <img
                            className="img-fulid"
                            src="assets/images/icons/mitech-home-resolutions-box-image-01-100x98.webp"
                            alt=""
                          />
                        </div>
                        <div className="hover-images">
                          <img
                            className="img-fulid"
                            src="assets/images/icons/mitech-home-resolutions-box-image-01-hover-100x98.webp"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Financing of the Added Value</h5>
                        <div className="text">
                          A need for Financing in the form of a bridge loan / insurance by the Holding HAZIKA SAS for local Projects.
                        </div>
                        <div className="box-images-arrow">
                          <Link to="#">
                            <span className="button-text">Discover now</span>
                            <i className="far fa-long-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 wow move-up">
                  <div className="ht-box-images style-05">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <div className="default-image">
                          <img
                            className="img-fulid"
                            src="assets/images/icons/mitech-home-resolutions-box-image-02-100x98.webp"
                            alt=""
                          />
                        </div>
                        <div className="hover-images">
                          <img
                            className="img-fulid"
                            src="assets/images/icons/mitech-home-resolutions-box-image-02-hover-100x98.webp"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Solidarity, Responsible, Sustainable Development</h5>
                        <div className="text">
                          Hazika holding France Investment fund as a private initiative
                        </div>
                        <div className="box-images-arrow">
                          <Link to="#">
                            <span className="button-text">Discover now</span>
                            <i className="far fa-long-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 wow move-up">
                  <div className="ht-box-images style-05">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <div className="default-image">
                          <img
                            className="img-fulid"
                            src="assets/images/icons/mitech-home-resolutions-box-image-03-100x98.webp"
                            alt=""
                          />
                        </div>
                        <div className="hover-images">
                          <img
                            className="img-fulid"
                            src="assets/images/icons/mitech-home-resolutions-box-image-03-hover-100x98.webp"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">A Federative private initiative, based on Human development and Solidarity</h5>
                        <div className="text">
                          Participatory Financing and various donations.
                        </div>
                        <div className="box-images-arrow">
                          <Link to="#">
                            <span className="button-text">Discover now</span>
                            <i className="far fa-long-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-under-heading text-center section-space--mt_80">
              Challenges are just opportunities in disguise.{" "}
              <Link to="#">Take the challenge!</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}