import React, { useState } from "react";

export default function ContactForm() {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://telcodemailer.herokuapp.com/api/hazika/sendContactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify(state)
      });
      const data = await response.json();
      if (data && data.message === "Email has been sent successfully") {
        setState({
          email: '',
          name: '',
          subject: '',
          message: ''
        });
        setMessage(data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-lg-6">
            <div className="conact-us-wrap-one mb-30">
              <h3 className="heading">
                To make requests for <br />
                further information, <br />
                <span className="text-color-primary">contact us</span> via our
                social channels.{" "}
              </h3>
              <div className="sub-heading">We will get right back to you!</div>
            </div>
          </div>

          <div className="col-lg-6 col-lg-6">
            {
              message && (
                <div className="alert alert-success" role="alert">
                  {message}
                </div>
              )
            }
            <div className="contact-form-wrap">
              <form
                // id="contact-form"
                onSubmit={handleSubmit}
              >
                <div className="contact-form">
                  <div className="contact-input">
                    <div className="contact-inner">
                      <input name="name" type="text" value={state.name} onChange={handleChange} placeholder="Name *" />
                    </div>
                    <div className="contact-inner">
                      <input
                        name="email"
                        type="email"
                        value={state.email}
                        onChange={handleChange}
                        placeholder="Email *"
                      />
                    </div>
                  </div>
                  <div className="contact-inner">
                    <input
                      name="subject"
                      type="text"
                      value={state.subject}
                      onChange={handleChange}
                      placeholder="Subject *"
                    />
                  </div>
                  <div className="contact-inner contact-message">
                    <textarea
                      name="message"
                      value={state.message}
                      onChange={handleChange}
                      placeholder="Please describe what you need*"
                    ></textarea>
                  </div>
                  <div className="submit-btn mt-20">
                    <button className="ht-btn ht-btn-md" type="submit" disabled={loading}>
                      {
                        loading ? "Loading..." : "Send message"
                      }
                    </button>
                    <p className="form-messege"></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
