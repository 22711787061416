const MobileOverlay = () => {
    return (
        <div className="mobile-menu-overlay" id="mobile-menu-overlay">
            <div className="mobile-menu-overlay__inner">
                <div className="mobile-menu-overlay__header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-8">
                                {/* <!-- logo --> */}
                                <div className="logo">
                                    <a href="/">
                                        <img src="assets/images/logo/logo-dark.webp" className="img-fluid" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-4">
                                {/* <!-- mobile menu content --> */}
                                <div className="mobile-menu-content text-end">
                                    <span className="mobile-navigation-close-icon" id="mobile-menu-close-trigger"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-overlay__body">
                    <nav className="offcanvas-navigation">
                        <ul>
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <a href="/aboutus">About us</a>
                            </li>
                            <li>
                                <a href="/aboutus">Contact us</a>
                            </li>
                            <li>
                                <a href="/aboutus">Blog</a>
                            </li>
                            <li>
                                <a href="/aboutus">FAQ</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
};

export default MobileOverlay;
