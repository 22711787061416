import Breadcrumb from "./components/Breadcrumb";
import Breakdown from "./components/Breakdown";
import Features from "./components/Features";
import Resources from "./components/Resources";
import Team from "./components/Team";

const Aboutus = () => {
  return (
    <>
      <Breadcrumb />
      <Features />
      <Resources />
      <Breakdown />
      <Team />
    </>
  );
};

export default Aboutus;
