import { Link, Outlet } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import MobileOverlay from "./components/MobileOverlay";
import Preloader from "./components/Preloader";


// https://meet.google.com/owu-zijq-zgf
const MainLayout = () => {
  return (
    <>
      <Preloader />
      <Header />
      <div className="main-wrapper">
        <div className="site-wrapper-reveal">
          <Outlet />
        </div>
        <Footer />
      </div>
      <Link to="#" className="scroll-top" id="scroll-top">
        <i className="arrow-top fal fa-long-arrow-up"></i>
        <i className="arrow-bottom fal fa-long-arrow-up"></i>
    </Link>
      <MobileOverlay />
    </>
  );
};

export default MainLayout;
