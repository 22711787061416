export default function Resources() {
  return (
    <div className="about-resources-wrapper">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="resources-left-box">
              <div className="resources-inner">
                <h6 className="sub-title mb-20">Resources</h6>
                <h3 className="heading">
                  Get a copy of brochure on{" "}
                  <span className="text-color-primary"> Hazika's Vision.</span>
                </h3>
                <div className="button mt-30">
                  <a href="/" className="ht-btn ht-btn-md">
                    Download now (3MB)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="resources-right-box">
              <div className="resources-right-inner text-center">
                <div className="resources-images">
                  <img
                    className="img-fluid"
                    src="assets/images/banners/managed-it-services-book-cover.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
