import Breadcrumb from "../../components/Breadcrumb";
import ContactForm from "./components/ContactForm";

const ContactUs = () => {
  return (
    <>
      <Breadcrumb title="Contact us" />
      <ContactForm />
    </>
  );
};

export default ContactUs;
