import Features from "./components/Features";
import FunFacts from "./components/FunFacts";
import Hero from "./components/Hero";
import OurCompany from "./components/OurCompany";

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <FunFacts />
      <OurCompany />
    </>
  )
};

export default Home;
