// import { Link } from "react-router-dom";

export default function Features() {
  return (
    <div className="feature-large-images-wrapper section-space--ptb_100">
      <div className="container">

        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-wrap text-center section-space--mb_60">
              <h6 className="section-sub-title mb-20">Our company</h6>
              <h3 className="heading">Mixed Use <span className="text-color-primary"> Development   <br /> Funding</span> Project: <span className="text-color-primary">Waste to Energy & Food Development</span></h3>
            </div>
          </div>
        </div>

        <div className="cybersecurity-about-box">
          <div className="row">
            <div className="col-lg-5 offset-lg-1">
              <div className="modern-number-01 number-two">
                <h2 className="heading  me-5"><span className="mark-text">38</span>Years’ Experience in IT</h2>
                <h5 className="heading mt-30">We’ve been triumphing all these <span className="text-color-primary"> 38 years. </span> Sacrifices are made up with success. </h5>
              </div>
            </div>

            <div className="col-lg-5 ms-auto">
              <div className="faq-wrapper">
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button className="btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          The Company's Vision. <span> <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i> </span>
                        </button>
                      </h5>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                      <div className="card-body">
                        <p>Improve the Environment, Well-being and Health. This will include;</p>
                        <li>The development of added value in waste processing, agricultural, poultry, fish, animal, and forestry projects</li>
                        <li>Securing the progressive and consistent sale of these resources.</li>
                        <li>The increase in capacity and the support of human resources to guarantee this well-being in the long term</li>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          The Challenge<span> <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i> </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                      <div className="card-body">
                        <li>Waste is a planetary gangrene, countries in difficulty buy foreign waste to support their needs</li>
                        <li>Food, often imported via long circuits , contributes to climate change and greenhouse gases</li>
                        <li>
                          The entire production chain (from sowing, rearing, planting, harvesting, preparation) is carried out by the young <br />
                          generations trained to apply innovative concepts that favor natural fertilizers of aquaculture and permaculture
                        </li>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          The Objectives <span> <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i> </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                      <div className="card-body">
                        <p>In coordination with cooperatives and local Hazika team:</p>
                        <li>To Develop the support of the whole Agricultural production chain in quality and quantity </li>
                        <li>Eliminate waste, increase safety, and facilitate marketing</li>
                        <li>Guarantee social benefits in each project</li>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
