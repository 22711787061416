const OurCompany = () => {
	return (
		<div className="our-company-history section-space--ptb_100">
			<div className="container-fluid">
				<div className="grid-wrapper">
					<div className="line line-1"></div>
					<div className="line line-2"></div>
					<div className="line line-3"></div>
					<div className="line line-4"></div>
				</div>

				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="faq-custom-col">
							<div className="section-title-wrap">
								<h6 className="section-sub-title mb-20">Our company</h6>
								<h3 className="heading">Improving <br /> the environment <span className="text-color-primary"> Well being</span> <br /> and Health.</h3>
								
								<ul>
									<li className="text mt-30">The Development of the added value in waste processing, agricultural, poultry, fish, animal and forestry projects.</li>
									<li className="text mt-30">Securing the progressive and consistent sale of these resources.</li>
									<li className="text mt-30">The increase in capacity and the support of human resources to guarantee this well-being in the long term.</li>
								</ul>

								<div className="inner-button-box section-space--mt_60">
									<a href="/aboutus" className="ht-btn ht-btn-md">Find out more</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="rv-video-section">

							<div className="ht-banner-01 ">
								<img className="img-fluid border-radus-5 animation_images one wow fadeInDown" width="188px" height="115px" src="https://media.sciencephoto.com/image/c0493014/400wm" alt="" />
							</div>

							<div className="ht-banner-02">
								<img className="img-fluid border-radus-5 animation_images two wow fadeInDown" width="188px" height="115px" src="https://media.sciencephoto.com/c0/49/30/13/c0493013-800px-wm.jpg" alt="" />
							</div>

							<div className="main-video-box video-popup">
								<a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="video-link  mt-30">
									<div className="single-popup-wrap">
										<img className="img-fluid border-radus-5" src="https://perfectdailygrind.com/wp-content/uploads/2017/11/coffee-bags-e1509741646869.jpg" alt="" />
										<div className="ht-popup-video video-button">
											<div className="video-mark">
												<div className="wave-pulse wave-pulse-1"></div>
												<div className="wave-pulse wave-pulse-2"></div>
											</div>
											<div className="video-button__two">
												<div className="video-play">
													<span className="video-play-icon"></span>
												</div>
											</div>
										</div>
									</div>
								</a>
							</div>


							<div className="ht-banner-03">
								<img className="img-fluid border-radus-5 animation_images three wow fadeInDown" width="188px" height="115px" src="https://previews.123rf.com/images/nimon/nimon1601/nimon160100008/50365082-coffee-beans-dried-in-the-sun-coffee-beans-raked-out-for-drying-prior-to-roasting.jpg" alt="" />
							</div>

							<div className="ht-banner-04">
								<img className="img-fluid border-radus-5 animation_images four wow fadeInDown" width="188px" height="115px" src="https://media.sciencephoto.com/image/c0493014/400wm" alt="" />
							</div>


						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default OurCompany;
