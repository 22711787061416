import { Navigate, useRoutes } from "react-router-dom";

import MainLayout from "./layout/MainLayout";
import { Aboutus, Contactus, Home } from "./pages";

const Router = () =>
  useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/home" replace />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "aboutus",
          element: <Aboutus />,
        },
        {
          path: "contactus",
          element: <Contactus />,
        },
      ],
    },
  ]);

export default Router;
