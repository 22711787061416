import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header-area header-area--absolute">
      <div className="header-top-bar-info border-bottom d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top-bar-wrap">
                <div className="top-bar-left">
                  {/* <div className="top-bar-text text-white">
                    <Link to="#" className="font-medium display-inline">
                      Now Hiring:
                    </Link>{" "}
                    Are you a driven and motivated 1st Line IT Support Engineer?
                  </div> */}
                </div>
                <div className="top-bar-right">
                  <ul className="top-bar-info">
                    <li className="info-item">
                      <Link
                        to="tel:01228899900"
                        className="info-link text-white"
                      >
                        <i className="info-icon fa fa-phone"></i>
                        <span className="info-text">
                          <strong>+33 7 72 30 75 35</strong>
                        </span>
                      </Link>
                    </li>
                    <li className="info-item text-white">
                      <i className="info-icon fa fa-map-marker-alt"></i>
                      <span className="info-text">
                        Mail Lamartine 6, MONS-EN-BARŒUL
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom-wrap header-sticky">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header position-relative">
                <div className="header__logo">
                  <Link to="/home">
                    <img
                      src="/logo.png"
                      width="160"
                      height="48"
                      className="img-fluid light-logo"
                      alt=""
                    />
                    <img
                      src="/logo.png"
                      width="160"
                      height="48"
                      className="img-fluid dark-logo"
                      alt=""
                    />
                  </Link>
                </div>

                <div className="header-right">
                  <div className="header__navigation menu-style-four d-none d-xl-block">
                    <nav className="navigation-menu">
                      <ul>
                        <li>
                          <Link to="/home">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/aboutus">
                            <span>Aboutus</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/contactus">
                            <span>Contact us</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/blog">
                            <span>Blog</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq">
                            <span>FAQ</span>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  <div className="header-search-form-two white-icon">
                    <form action="#" className="search-form-top-active">
                      <div className="search-icon" id="search-overlay-trigger">
                        <Link to="#">
                          <i className="fa fa-search"></i>
                        </Link>
                      </div>
                    </form>
                  </div>

                  <div
                    className="mobile-navigation-icon white-md-icon d-block d-xl-none"
                    id="mobile-menu-trigger"
                  >
                    <i></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
